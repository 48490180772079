.container{
  margin-top: 20px;
  padding: 25px 20px;
  background: var(--qm-color-primary);
  .wrap{
    width: var(--qm-layout-width);
    margin: 0 auto;
    color: white;
    font-size: var(--qm-font-size-2);
    p{
      text-align: center;
      margin-top: 10px;
      &:first-child{
        margin-top: 0;
      }
      a{
        color: white;
      }
    }
  }
}