.container{
  margin-top: 20px;
  min-height: 480px;
  border: 1px solid #cb7a43;
  display: flex;
  .good,
  .bad{
    flex: 0 0 360px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    a{
      color: var(--qm-color-text);
      &:hover{
        text-decoration: underline;
      }
    }
    .head{
      min-height: 250px;
      display: flex;
      flex-direction: column;
      .top{
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid var(--qm-border-color);
        .badge{
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 999px;
          font-size: 36px;
          position: relative;
          &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 120%;
            height: 120%;
            transform: translate(-50%, -50%);
            border-radius: 999px;
          }
        }
      }
      .bottom
      {
        flex: 1;
        position: relative;
        &::before,
        &::after,
        ul::before
        {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          background: var(--qm-border-color);
        }
        &::before{
          left: 25%;
        }
        &::after{
          left: 50%;
        }
        ul{
          width: 100%;
          height: 100%;
          padding-bottom: 6px;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          position: relative;
          &::after{
            position: static;
            content: '';
            width: 25%;
            height: 22px;
          }
          li{
            width: 25%;
            height: 22px;
            line-height: 22px;
            margin-top: 6px;
            text-align: center;
            font-size: var(--qm-font-size-2);
            color: var(--qm-color-text);
          }
          &::before{
            left: 75%;
          }
        }
      }
    }
    .foot{
      flex: 0 0 229px;
      display: flex;
      flex-direction: column;
      .title{
        height: 33px;
        line-height: 33px;
        color: white;
        display: flex;
        transition: opacity .2s ease-in;
        background: #cb7a43;
        span{
          vertical-align: middle;
          &:first-child{
            flex: 1;
            text-align: center;
            padding-left: 12px;
          }
          &[class*=anticon]{
            padding: 0 6px;
          }
        }
      }
      .column{
        height: 100%;
        display: flex;
        position: relative;
        color: var(--qm-color-text);
        .left,
        .right{
          flex: 1;
          display: flex;
          flex-direction: column;
          text-align: center;
          h4{
            height: 50px;
            line-height: 50px;
            font-size: var(--qm-font-size-4);
            background: var(--qm-color-box);
          }
          ul{
            width: 100%;
            height: 100%;
            padding-bottom: 6px;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            position: relative;
            li{
              height: 22px;
              line-height: 22px;
              font-size: var(--qm-font-size-2);
              margin-top: 6px;
            }
          }
        }
        .left{
          ul{
            li{
              width: 50%;
            }
          }
        }
        .right{
          border-left: 1px solid var(--qm-border-color);
          ul{
            li{
              width: 100%;
            }
          }
        }
      }
    }
  }
  .good{
    .head{
      .top{
        .badge{
          color: white;
          background: var(--qm-color-success);
          &::before{
            border: 2px solid var(--qm-color-success);
            opacity: .5;
          }
        }
      }
    }
  }
  .bad{
    .head{
      .top{
        .badge{
          color: white;
          background: var(--qm-color-danger);
          &::before{
            border: 2px solid var(--qm-color-danger);
            opacity: .5;
          }
        }
      }
    }
  }
  .carry{
    flex: 1;
    background: #FCF8EF;
    .solardate,
    .lunardate,
    .extra
    {
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-weight: bold;
      font-size: var(--qm-font-size-3);
      color: var(--qm-color-text);
      a{
        color: var(--qm-color-text);
        text-decoration: underline;
      }
      span{
        color: var(--qm-color-primary);
      }
    }
    .extra{
      font-weight: normal;
      font-size: var(--qm-font-size-2);
      span[class=good]{
        color: var(--qm-color-success);
      }
      span[class=bad]{
        color: var(--qm-color-danger);
      }
    }
    .note{
      font-size: var(--qm-font-size-1);
      text-align: center;
      color: var(--qm-color-text-secondary);
      position: relative;
      top: -6px;
    }
    .solardate{
      padding-top: 12px;
    }
    .solarday{
      width: 90%;
      margin: 0 auto;
      height: 140px;
      line-height: 140px;
      display: flex;
      justify-content: center;
      .prev,.next{
        cursor: pointer;
        font-size: 36px;
        color: var(--qm-color-primary);
      }
      .num{
        flex: 1;
        text-align: center;
        color: var(--qm-color-primary);
        font-size: 120px;
        font-weight: bold;
      }
    }
    .pengzu{
      width: 90%;
      margin: 16px auto;
      border: 1px solid #F4C493;
      padding: 12px 8px;
      border-radius: var(--qm-radius-s);
      background: rgba(255, 255, 255, 0.8);
      p{
        color: var(--qm-color-text);
        font-size: var(--qm-font-size-2);
        text-align: center;
        margin-top: 12px;
        &:first-child{
          margin-top: 0;
        }
        a{
          color: var(--qm-color-text);
          &[datatype=underline],
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
    .hours{
      width: 90%;
      margin: 20px auto 0;
      display: flex;
      margin-top: 20px;
      font-size: var(--qm-font-size-2);
      .label{
        width: 5%;
        line-height: 1.3;
        text-align: center;
        color: var(--qm-color-primary);
        a{
          width: 100%;
          height: 100%;
          display: block;
          color: var(--qm-color-primary);
        }
        &:last-child{
          cursor: pointer;
        }
      }
      .value{
        flex: 1;
        ul{
          display: flex;
          padding-right: 10px;
          li{
            color: var(--qm-color-text);
            line-height: 1.3;
            margin-left: 10px;
            span{
              &::before{
                content: '丨';
                opacity: .1;
              }
            }
            &[class=active]{
              color: #cb7a43;
            }
          }
        }
      }
    }
  }
}