.infoflow{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -20px;
  width: auto;
  .infocolumn{
    flex: 1;
  }
  div[class*=infoitem]{
    margin-top: 20px;
    padding-left: 20px;
    h3{
      height: 30px;
      line-height: 30px;
      font-size: var(--qm-font-size-3);
      font-weight: bold;
      text-align: center;
      border-radius: var(--qm-radius-s);
      color: #cb7a43;
    }
    div[class=body]{
      position: relative;
      margin-top: 10px;
      padding: 6px;
      border-radius: var(--qm-radius-s);
      font-size: var(--qm-font-size-2);
      background: linear-gradient(90deg, #FDD39D 0%, #F1B872 100%);
      &::before,
      &::after{
        content: '';
        position: absolute;
        width: 10px;
        height: 32px;
        top: -15px;
        background: center / contain url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAA8CAYAAABW+ot3AAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAHhSURBVEiJ7ZexaxNRHMc/712eKBridegQPJSDDNVAQnESsRZKxUqH0sHMdZTrkD+kYG+2cxA6lAxiKdQirrWVooWDaL0QpAiS+Uqeg3eXK5remcnhvtO7x/dzv/f78YbvEyTkNqwygXIQLAKVcNtD00YFrtPye5FXRIv1ZXtFCNaAEn9XX2uaq5udjRgMoZcjgHPSmmerm50N4TasMmfq0wWV/qhMIbhdCHuKIbtaoz4zx+SNmwCcdk842Nuhc3QYWUoEyjEW7pgvgEmAe0+WeLD0lKI5gTQMpGFQNCeo1O9SUJfwveNoMqYknJ5drTE9Oz/yfNOz89jVWvRZkdGqPjOX2lzSIwEPiHu6SAmPJ9G0AQpKpYKxR9OWqMAF+qnUUH1U4Eqn5fe0ppmV0pqm0/J78ZXTX9/oLKC49UjA7+GMpRzMwRzMwRzMwRz8b8D1ZXslKxB5pduwymFOzSQhWHMbVlkSKIfskRPC9CjDGM1ZEKQSsUewGKfH0+5JKpjwDNPjwd5OKpj0xOmxc3TI/u72SGh/dzuZkj1jYcq0ENwH8L1jfvS6XC1d58q1IoPBgO/fvvBu6xUf378d/kUzfpgfOz0aAK8///zweMr0heAhcHlUJa15fu7BEulfnki/ANMBqtMsYB+9AAAAAElFTkSuQmCC) no-repeat;
      }
      &::before{
        left: 10px;
      }
      &::after{
        right: 10px;
      }
      div[class=wrap]{
        color: var(--qm-color-text);
        border-radius: var(--qm-radius-s);
        background: #FCF8EF;
        span{
          color: var(--qm-color-primary);
        }
        b{
          svg{
            color: var(--qm-color-text);
          }
        }
        div[class=title]{
          word-break: break-all;
          text-align: justify;
          line-height: 1.5;
          padding: 8px 10px;
          font-weight: bold;
          color: var(--qm-color-text);
          background: white;
        }
        div[class=content]{
          padding: 8px 10px;
          line-height: 1.5;
          word-break: break-all;
          text-align: justify;
          p{
            margin-top: 8px;
            &:first-child{
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}