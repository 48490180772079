.container{
  .color{
    .good,
    .nice,
    .fine,
    .low,
    .bad{
      margin-top: 6px;
      padding: 6px 12px;
      border-radius: var(--qm-radius-s);
      display: flex;
      div[class=left]{
        flex: 1;
        align-self: center;
        img{
          width: 70px;
        }
      }
      div[class=right]{
        padding-top: 3px;
        display: flex;
        align-self: center;
        text-align: center;
        div[class=dot]{
          font-size: var(--qm-font-size-2);
          margin-left: 10px;
          color: var(--qm-color-text);
          i{
            width: 33px;
            height: 33px;
            display: block;
            margin: 0 auto;
            border-radius: 999px;
          }
        }
      }
    }
    .good{
      background-color: rgba(191, 55, 47, 0.1);
    }
    .nice{
      background-color: rgba(191, 99, 47, 0.1);
    }
    .fine{
      background-color: rgba(191, 133, 47, 0.1);
    }
    .low{
      background-color: rgba(191, 168, 47, 0.1);
    }
    .bad{
      background-color: rgba(171, 191, 47, 0.1);
    }
  }
}