.container{
  margin-top: 20px;
  border: 1px solid #cb7a43;
  .head{
    font-weight: normal;
    display: flex;
    justify-content: center;
    padding: 5px;
    background: #cb7a43;
    .yearlist,
    .monthlist,
    .gooddaylist,
    .holidaylist,
    .today{
      margin-left: 5px;
      font-size: var(--qm-font-size-2);
      color: var(--qm-color-text);
    }
    .today{
      border-radius: 6px;
      background: white;
      span{
        padding: 8px;
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        &[class*=anticon]{
          font-size: var(--qm-font-size-1);
          background: var(--qm-color-box);
        }
      }
    }
    .yearlist{
      margin-left: 0;
    }
    .extra{
      font-size: var(--qm-font-size-2);
      color: white;
      align-self: center;
      margin-left: 12px;
      cursor: pointer;
      a{
        color: white;
      }
      span{
        vertical-align: middle;
      }
      span[class*=anticon]{
        font-size: var(--qm-font-size-1);
        margin-left: 3px;
      }
    }
  }
  .week{
    display: flex;
    li{
      padding: 5px;
      color: var(--qm-color-text);
      font-size: var(--qm-font-size-3);
      flex: 1;
      text-align: center;
      border: 1px solid var(--qm-border-color);
      border-left: none;
      border-bottom: none;
      background: var(--qm-color-box);
      &:last-child{
        border-right: none;
      }
    }
  }
  .datelist{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-start;
    .date{
      padding: 5px;
      color: var(--qm-color-text);
      flex: 0 0 calc(100% / 7);
      text-align: center;
      border-top: 1px solid var(--qm-border-color);
      border-left: 1px solid var(--qm-border-color);
      cursor: pointer;
      position: relative;
      &:hover{
        background: var(--qm-color-box);
      }
      &.holiday
      {
        background: #f1f9f1;
        &:hover{
          background: #e5f5e5;
        }
        &::before{
          font-size: var(--qm-font-size-2);
          content: '休';
          position: absolute;
          top: 5px;
          right: 5px;
          color: var(--qm-color-success);
        }
      }
      &.work{
        background: #fef0ef;
        &:hover{
          background: #ffe8e7;
        }
        &::before{
          font-size: var(--qm-font-size-2);
          content: '班';
          position: absolute;
          top: 5px;
          right: 5px;
          color: var(--qm-color-danger);
        }
      }
      &.current{
        background: #fff2dd;
        &:hover{
          background: #ffeed0;
        }
        &::before{
          font-size: var(--qm-font-size-2);
          content: '今' !important;
          position: absolute;
          top: 5px;
          right: 5px;
          color: var(--qm-color-warning);
        }
      }
      &.active{
        border: 3px solid var(--qm-color-warning) !important;
      }
      &:nth-child(7n + 1){
        border-left: none;
      }
      &.before,
      &.after
      {
        &::before,
        p{
          opacity: .3;
          font-weight: normal;
        }
      }
      p{
        padding: 3px 0;
        &:first-child{
          font-size: var(--qm-font-size-4);
          font-weight: 500;
        }
        &:nth-child(2){
          font-size: var(--qm-font-size-2);
          color: var(--qm-color-text-secondary);
        }
      }
      &.goodday{
        color: white;
        background: #ff9a9a;
        &:hover{
          background: #fdb3b3;
        }
        p{
          color: white;
        }
        &::before{
          padding: 5px;
          font-size: var(--qm-font-size-1);
          border-radius: 999px;
        }
        &.holiday{
          &::before{
            color: white;
            background: var(--qm-color-success);
          }
        }
        &.current{
          &::before{
            background: #fff2dd;
          }
        }
        &.work{
          &::before{
            color: white;
            background: var(--qm-color-danger);
          }
        }
        &.active{
          border-color: var(--qm-color-danger) !important;
        }
      }
    }
  }
}