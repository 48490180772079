.rows{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  &::after{
    content: '';
    width: calc(33.33% - 5px);
  }
  .item{
    width: 0;
    margin-top: 8px;
    flex: 0 0 calc(33.33% - 5px);
    border: 1px solid var(--qm-border-color);
    box-shadow: 0 2px 8px var(--qm-layer-fill-1);
    &:first-child,
    &:nth-child(2),
    &:nth-child(3){
      margin-top: 0;
    }
    div[class*=day],
    div[class*=luck]{
      padding: 5px 10px;
      text-align: center;
      background: white;
      span{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    div[class*=day]{
      border-bottom: 1px solid var(--qm-border-color);
    }
    div[class*=luck]{
      &[class*=good]{
        color: var(--qm-color-success);
      }
      &[class*=bad]{
        color: var(--qm-color-danger);
      }
    }
  }
}