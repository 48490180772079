.container{
  height: 64px;
  border-bottom: 1px solid var(--qm-border-color);
  box-shadow: 0 2px 8px var(--qm-layer-fill-1);
  .wrap{
    width: var(--qm-layout-width);
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .logo{
      width: 220px;
    }
    .menu{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      li{
        min-width: 64px;
        height: 64px;
        line-height: 64px;
        padding: 0 12px;
        text-align: center;
        a{
          width: 100%;
          height: 100%;
          display: block;
          color: var(--qm-color-text);
        }
        &:last-child{
          padding-right: 0;
        }
        &[class=download]{
          a{
            span{
              border-radius: 999px;
              font-size: var(--qm-font-size-2);
              padding: 10px 20px;
              color: white;
              background: var(--qm-color-primary);
            }
          }
        }
      }
    }
  }
}