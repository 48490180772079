.container{
  padding-bottom: 3px;
  .title{
    margin-top: 12px;
    color: var(--qm-color-text);
    border-bottom: 1px solid rgba(191, 55, 47, 0.2);
    padding-bottom: 6px;
    font-weight: bold;
    &:first-child{
      margin-top: 0;
    }
  }
  .holiday{
    margin-top: 8px;

    .subtitle{
      font-weight: bold;
    }

    &,span{
      color: var(--qm-color-text) !important;
    }

    div[class*=ant-typography]{
      margin-top: 3px;
      margin-bottom: 0;
      // font-size: var(--qm-font-size-3);
    }

    a[class=ant-typography-expand]{
      color: var(--qm-color-primary);
    }
  }
  .list{
    margin-top: 3px;
    .row{
      display: flex;
      padding: 6px 0 0;
      div[class=left]{
        width: 60px;
        margin-right: 12px;
        color: var(--qm-color-text);
        position: relative;
        &::before{
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 60%;
          transform: translateY(-50%);
          background: rgba(191, 55, 47, 0.2);
        }
      }
      div[class=right]{
        line-height: 1.5;
        flex: 1;
        text-align: justify;
      }
    }
  }
}