.container{
  display: flex;
  justify-content: space-around;
  .circle{
    width: 40px;
    height: 40px;
    font-size: var(--qm-font-size-3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 999px;
    background: var(--qm-color-primary);
  }
}