.container{
  padding: 20px 10px 0;
  ul{
    li:last-child{
      padding-bottom: 10px;
      div[class*=ant-timeline-item-content]{
        min-height: auto;
      }
    }
  }
  div[class*=ant-timeline-item-label]{
    text-align: left !important;
    width: 100px !important;
  }
  div[class*=ant-timeline-item-tail],
  div[class*=ant-timeline-item-head],
  div[class*=ant-timeline-item-content]
  {
    left: 20% !important;
  }
  div[class*=ant-timeline-item-content]{
    width: calc(76% - 10px) !important;
  }
}