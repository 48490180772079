.rows{
  line-height: 1;
  .row{
    margin-top: 10px;
    display: flex;
    border: 1px solid #cb7a43;
    .left,
    .right{
      padding: 10px;
    }
    .left{
      color: #cb7a43;
      font-weight: bold;
      width: 80px;
      border-right: 1px solid #cb7a43;
      text-align: center;
    }
  }
}