.tungshing{
  margin-top: 20px;
  .title{
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    font-size: var(--qm-font-size-2);
    color: white;
    background: #cb7a43 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAcoAAAAICAMAAABOFU/lAAAA21BMVEUAAAD/yK7/yar/yKr/x63/yKv/x7D/1J//2qT/xa//z6b/0qP/2J//1Z7/xa//xa//xa7/xa//xq//xa//y6r/1aT/1KL/1qD/1p3/2p7/457/xq//xq3/xLD/yqj/xbD/zKb/xa//zaf/xbH/0KP/zKr/06L/16H/x67/06H/x7D/zar/x7L/yqr/xa3/waz/5KT/xLH/xLD/xq7/xa//xa7/yqn/xq//xK//xa7/zab/z6X/z6T/xLD/0qP/x67/1aH/1KD/xrD/xa3/xrH/2KD/2aH/z6X/zaW92NAyAAAASXRSTlMAS0u6u7otHA3URjomBfXu6+aVVj81LyshFhL+6MixqqejnY9+c2pVQ0E4MykjGhIJ+97azsG6traelZCGgXduYl5aVE5OSYuLk/rJcQAAAUhJREFUSMft0VVyg1AYQOGfhlzcnbjhEOLu0u5/RSUzmUmfA5O+8C3hHMjlSilQKohCXeG/DDu8LLSGUCrAsCXIfCdXTI5LSZK0bcsiCMI0WZY1DF0PNY1hGFX1aZr++gN7SZBEEZC0JAxKuWFSKwGCklCCvXxhr/LZCF9VsymaFuq6YWSjTDNbZlm2nQ1MOQ5I2yJM1tA1xqfvN9c9HR1nsO/3tz+bdbfbbjeblXnlqZrBq3hGqOOicuB70UxVRj0o5dQbKeos6vEHRcTrAv5Qfag8zSvNZrvd7a4339t+fz9wnOPJdW932mc03WBNwrJJeA+qXzxRDqiaHDUaMZRyihuNSK5RgSx6lzqCj0LSYFKjQu8s7Dgo5cbthLMXUrXJQELwYUhcoCm/HAdQKkQwXvJTtBARfFzMQbrqkFAqCNlZpcDF8K5fy4khL6WpiFoAAAAASUVORK5CYII=) no-repeat 50%;
  }
  table{
    width: 100%;
    table-layout: fixed;
    color: var(--qm-color-text);
    font-size: var(--qm-font-size-2);
    tr{
      &:first-child{
        td{
          font-weight: bold;
          background: #FCF8EF;
        }
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:last-child
      {
        td{
          text-align: left;
          span{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-content: flex-start;
            &[datatype=one]{
              a{
                width: 100%;
              }
              &::after{
                display: none;
              }
            }
            a{
              width: 50%;
              font-weight: normal;
              text-align: center;
              align-self: center;
              color: var(--qm-color-text);
              // &:hover{
              //   cursor: pointer;
              //   text-decoration: underline;
              // }
            }
            &::after{
              content: '';
              width: 50%;
            }
          }
        }
        td:first-child{
          font-size: var(--qm-font-size-2);
          text-align: center;
        }
      }
      &:last-child{
        td{
          text-align: center;
        }
      }
      &.yi{
        td{
          &,
          span > a{
            color: var(--qm-color-success);
          }
        }
      }
      &.ji{
        td{
          &,
          span > a{
            color: var(--qm-color-danger);
          }
        }
      }
      &.chong{
        td{
          &,
          span > a{
            color: var(--qm-color-warning);
          }
        }
      }
      &.sha{
        td{
          &,
          span > a{
            color: var(--qm-color-info);
          }
        }
      }
    }
    td{
      line-height: 1.5;
      padding: 5px;
      border: 1px solid #EBD8A5;
      border-left: none;
      border-top: none;
      text-align: center;
      &:first-child{
        width: 50px;
        font-weight: bold;
        border-left: 1px solid #EBD8A5;
        background: #FCF8EF;
      }
      &.active{
        background: #FCF8EF;
      }
    }
  }
}