.container{
  .list{
    .row{
      display: flex;
      padding: 6px 0;
      border-top: 1px solid rgba(191, 55, 47, .2);
      &:first-child{
        padding-top: 0;
        border-top: none;
      }
      // &:hover{
      //   background: rgba(250, 206, 127, .2);
      // }
      div[class=left]{
        padding-right: 12px;
        margin-right: 12px;
        color: var(--qm-color-text);
        font-weight: bold;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 60%;
          transform: translateY(-50%);
          background: var(--qm-color-primary);
        }
      }
      div[class=right]{
        flex: 1;
        color: var(--qm-color-primary);
      }
    }
  }
}