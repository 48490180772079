.container{
  padding: 10px 10px 0;
  ul{
    li{
      &:last-child{
        padding-bottom: 0;
      }
    }
  }
  div[class*=ant-timeline-item-head]{
    background: transparent;
  }
  .dot{
    padding: 5px;
    border-radius: 999px;
    color: #cb7a43;
    border: 1px solid #cb7a43;
    background: #FCF8EF;
    &.active{
      color: white;
      background: #cb7a43;
    }
  }
  .card{
    position: relative;
    padding: 8px 10px;
    border-radius: var(--qm-radius-m);
    border: 1px solid #FCE4DC;
    background: #FFF9F7;
    .good,
    .bad
    {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 8px;
      color: white;
      border-bottom-left-radius: var(--qm-radius-m);
      border-top-right-radius: var(--qm-radius-m);
      &.bad{
        background: var(--qm-color-danger);
      }
      &.good{
        background: var(--qm-color-success);
      }
    }
    .yi{
      color: var(--qm-color-success);
    }
    .ji{
      color: var(--qm-color-danger);
    }
    .row{
      color: var(--qm-color-text);
      span{
        color: #cb7a43 !important;
      }
    }
  }
}