.qrcode{
  text-align: center;
  p{
    margin-top: 6px;
    &:first-child{
      margin-top: 0;
    }
  }
  img{
    width: 100px;
    height: 100px;
  }
}