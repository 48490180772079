.container{
  .vacation{
    opacity: 1;
  }
  border: 1px solid #cb7a43;
  .head{
    display: flex;
    color: var(--qm-color-primary);
    .item{
      font-weight: bold;
      padding: 8px 0;
      flex: 1;
      text-align: center;
      border-left: 1px solid #cb7a43;
      &:first-child{
        border-left: none;
      }
    }
  }
  .body{
    .row{
      display: flex;
      color: var(--qm-color-text);
      .col{
        padding: 8px 0;
        flex: 1;
        text-align: center;
        border-left: 1px solid #cb7a43;
        border-top: 1px solid #cb7a43;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:first-child{
          font-weight: bold;
          border-left: none;
        }
      }
    }
  }
}